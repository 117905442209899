<template>
    <div class="ranking py-3">
        <div class="podium d-flex justify-center align-end">
            <div class="thirdPlace">
                <div class="place text-center">
                    <v-icon color="bronze" x-large>$wreathlaurel_l</v-icon>
                    <b class="bronze--text">3</b>
                </div>
                <v-avatar size="160">
                    <v-img v-if="thirdPlace" :src="api+'/user/'+thirdPlace.id+'/getAvatar?token=' + $store.state.auth.idToken"></v-img>
                    <v-icon color="bronze" v-else>$user_s</v-icon>
                </v-avatar>
                <h5 class="text-center mt-3 white--text"  v-if="thirdPlace">{{ thirdPlace.firstname }}</h5>
                <h5 class="text-center white--text" v-if="thirdPlace" style="text-transform:uppercase;">{{ thirdPlace.name }}</h5>
                <h5 class="text-center mt-3 white--text" v-else style="text-transform:uppercase;">-</h5>
            </div>
            <div class="firstPlace">
                <div class="place text-center">
                    <v-icon color="gold" x-large>$wreathlaurel_l</v-icon>
                    <b class="gold--text">1</b>
                </div>
                <v-avatar size="180">
                    <v-img v-if="firstPlace" :src="api+'/user/'+firstPlace.id+'/getAvatar?token=' + $store.state.auth.idToken"></v-img>
                    <v-icon color="gold" v-else>$user_s</v-icon>
                </v-avatar>
                <h5 class="text-center mt-3 white--text" v-if="firstPlace">{{ firstPlace.firstname }}</h5>
                <h5 class="text-center white--text" v-if="firstPlace" style="text-transform:uppercase;">{{ firstPlace.name }}</h5>
                <h5 class="text-center mt-3 white--text" v-else style="text-transform:uppercase;">-</h5>
            </div>
            <div class="secondPlace">
                <div class="place text-center">
                    <v-icon color="silver" x-large>$wreathlaurel_l</v-icon>
                    <b class="silver--text">2</b>
                </div>
                <v-avatar size="160">
                    <v-img  v-if="secondPlace" :src="api+'/user/'+secondPlace.id+'/getAvatar?token=' + $store.state.auth.idToken"></v-img>
                    <v-icon  color="silver" v-else>$user_s</v-icon>
                </v-avatar>
                <h5 class="text-center mt-3 white--text" v-if="secondPlace">{{ secondPlace.firstname }}</h5>
                <h5 class="text-center white--text" style="text-transform:uppercase;"  v-if="secondPlace">{{ secondPlace.name }}</h5>
                <h5 class="text-center mt-3 white--text" v-else style="text-transform:uppercase;">-</h5>
            </div>
        </div>
        <div class="px-12 mt-8" v-if="restOfRanking && restOfRanking.length > 0">
            <div class="rank mt-2 pa-2 px-4 d-flex align-center" v-for="(user, index) in restOfRanking">
                <span style="font-size:24px; color: #1E4169;"><b>#{{ index + 4 }}</b></span>
                <v-avatar size="40" class="mx-4">
                    <v-img :src="api+'/user/'+user.id+'/getAvatar?token=' + $store.state.auth.idToken"></v-img>
                </v-avatar>
                <span style="font-size:20px; color: #1E4169;"><b>{{ user.firstname }} {{ user.name }}</b></span>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'rankingCoachs',
    props: ['ranking'],
    data() {
        return {
            api:this.$store.state.auth.apiUrl,
            rand: Math.random()
        }
    },
    computed: {
        firstPlace() {
            if(this.ranking && this.ranking.length > 0)
                return this.ranking[0];

            return null;
        },
        secondPlace() {
            if(this.ranking && this.ranking[1])
                return this.ranking[1];

            return null;
        },
        thirdPlace() {
            if(this.ranking && this.ranking[2])
                return this.ranking[2];

            return null;
        },
        restOfRanking() {
            if(this.ranking && this.ranking.length > 3)
                return this.ranking.slice(3, 7);

            return [];
        }
    },
}
</script>
<style lang="scss">
    .ranking {
        background-color: var(--v-darkblue-base) !important;
    }
    .place {
        position:relative;
        b {
            font-size:28px;
            position:absolute;
            top:52%;
            left:50%;
            transform:translate(-50%, -50%);
        }
    }
    .podium {
        position:relative;
    }
    .firstPlace {
        width:180px !important;
        z-index:2;
        .v-avatar {
            border: 3px solid #E3C034;
            background-color: var(--v-darkblue-base) !important;
            -webkit-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
            -moz-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
            box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
        }
        .v-icon {
            font-size: 45px !important;
            width:60px !important;
            height:60px !important;
            svg {
                font-size: 45px !important;
                width:60px !important;
                height:60px !important;
            }
        }
    }
    .secondPlace {
        width:160px !important;
        position:absolute;
        right:12%;
        .place{
            b
            {
                font-size:20px;
            }
        }
        .v-avatar {
            border: 3px solid #B7B7B7;
            -webkit-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
            -moz-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
            box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
        }
        .v-icon {
            font-size: 45px !important;
            width:50px !important;
            height:50px !important;
            svg {
                font-size: 45px !important;
                width:50px !important;
                height:50px !important;
            }
        }
    }
    .thirdPlace {
        width:160px !important;
        position:absolute;
        left:12%;
        .place{
            b
            {
                font-size:20px;
            }
        }
        .v-avatar {
            border: 3px solid #CCA76E;
            -webkit-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
            -moz-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
            box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
        }
        .v-icon {
            font-size: 45px !important;
            width:50px !important;
            height:50px !important;
            svg {
                font-size: 45px !important;
                width:50px !important;
                height:50px !important;
            }
        }
    }
    .rank {
        background-color: #FFF !important;
        border-radius: 25px;
    }
</style>